import React from 'react'

type Props = {
  isWhite?: boolean
  isShort?: boolean
  isDark?: boolean
  style?: object
}

export function Logo({ isWhite = false, isShort = false, isDark = false, style = {} }: Props) {
  return (
    <svg
      className="logo"
      style={style}
      width={isShort ? '45' : '182'}
      height="45"
      viewBox={`0 0 ${isShort ? '45' : '182'} 45`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {!isShort && (
        <>
          <path
            d="M133.987 14.4959C134.128 13.9597 134.185 13.3388 134.185 12.8026C134.185 9.52898 131.839 7.8075 128.393 7.8075C125.256 7.8075 122.827 9.58542 122.121 13.2542L119.21 28.2113H121.952L123.816 18.6444H131.444L131.897 16.1891H124.296L124.805 13.5081C125.229 11.2787 126.556 10.2909 128.28 10.2909C130.173 10.2909 131.472 11.1658 131.472 13.1131C131.472 13.4517 131.444 13.9314 131.275 14.4959H133.987ZM137.979 14.2701H135.267L133.967 20.9021C133.854 21.4665 133.769 22.1438 133.769 22.7928C133.769 26.5745 136.538 28.5782 140.183 28.5782C143.912 28.5782 146.681 26.4334 147.5 22.2284L149.054 14.2701H146.342L144.816 21.9745C144.279 24.7401 142.584 26.0947 140.295 26.0947C138.035 26.0947 136.509 24.7966 136.509 22.5389C136.509 22.0309 136.595 21.4665 136.651 21.1842L137.979 14.2701ZM160.184 28.2113H162.896L164.195 21.5794C164.309 21.0149 164.393 20.3377 164.393 19.6886C164.393 15.907 161.597 13.9032 157.98 13.9032C154.223 13.9032 151.482 16.048 150.662 20.253L149.109 28.2113H151.821L153.346 20.507C153.884 17.7413 155.579 16.3867 157.867 16.3867C160.127 16.3867 161.653 17.6849 161.653 19.9425C161.653 20.4505 161.569 21.0149 161.511 21.2971L160.184 28.2113ZM173.404 28.5782C176.993 28.5782 179.959 26.4616 181.032 23.1597H178.321C177.417 24.9941 175.693 26.0947 173.631 26.0947C170.777 26.0947 169.082 24.3168 169.082 21.8898C169.082 18.9548 171.258 16.3867 174.337 16.3867C176.597 16.3867 178.179 17.5438 178.688 19.3217H181.456C180.948 15.9634 178.066 13.9032 174.506 13.9032C169.929 13.9032 166.341 17.5156 166.341 22.0873C166.341 25.7561 169.11 28.5782 173.404 28.5782Z"
            fill={isWhite ? 'var(--white)' : 'var(--brandColor)'}
          />
          <path
            d="M66.5006 14.6446C66.5006 16.9307 65.7769 18.66 64.3293 19.8323C62.9013 20.9852 60.9452 21.5615 58.461 21.5615H56.231V28.3319H51.4189V8.02087H57.8155C60.6323 8.02087 62.7841 8.57774 64.2706 9.69147C65.7573 10.7857 66.5006 12.4368 66.5006 14.6446ZM61.5712 14.6446C61.5712 12.5149 60.3487 11.45 57.9035 11.45H56.231V18.1031H58.1382C59.2336 18.1031 60.0748 17.8198 60.6616 17.2531C61.268 16.6865 61.5712 15.817 61.5712 14.6446Z"
            fill={isDark ? 'var(--darkestGray)' : 'var(--white)'}
          />
          <path
            d="M72.8461 24.4357C72.8461 25.084 72.9602 25.5484 73.1882 25.8287C73.4338 26.0915 73.7933 26.2229 74.267 26.2229C74.688 26.2229 75.1177 26.0915 75.5562 25.8287C75.9948 25.5484 76.3543 25.1717 76.635 24.6985V14.8955H80.7921V28.8511H77.1612L76.977 27.2216C76.521 27.8874 75.907 28.4043 75.1352 28.7722C74.3634 29.1227 73.5478 29.2978 72.6883 29.2978C71.3727 29.2978 70.3729 28.9211 69.6887 28.1678C69.0222 27.3968 68.689 26.328 68.689 24.9613V14.8955H72.8461V24.4357ZM95.1216 14.475C95.9635 14.475 96.7352 14.6151 97.4369 14.8955L96.6739 20.73H94.0428V18.1807C93.271 18.3033 92.5869 18.7151 91.9905 19.4159C91.3941 20.1168 90.9292 21.0016 90.596 22.0704V26.0652H93.3587V28.8511H84.597V26.0652H86.4388V17.6551H84.597V14.8955H89.6224L90.3592 18.023C90.8679 16.8316 91.5081 15.9468 92.2799 15.3685C93.0517 14.7728 93.9989 14.475 95.1216 14.475ZM103.847 23.0953C104.11 25.2154 105.232 26.2754 107.214 26.2754C108.407 26.2754 109.6 25.89 110.793 25.1191L112.451 27.3793C111.767 27.975 110.95 28.4481 110.004 28.7985C109.056 29.1315 108.012 29.2978 106.872 29.2978C105.311 29.2978 103.987 28.9913 102.899 28.378C101.812 27.7472 100.987 26.88 100.426 25.7761C99.8823 24.6548 99.6104 23.3581 99.6104 21.8864C99.6104 20.4847 99.8736 19.2232 100.4 18.1018C100.944 16.963 101.724 16.0694 102.742 15.4211C103.776 14.7553 105.004 14.4224 106.425 14.4224C107.759 14.4224 108.915 14.7115 109.898 15.2897C110.88 15.8503 111.634 16.6738 112.161 17.7602C112.705 18.829 112.976 20.108 112.976 21.5973C112.976 22.1755 112.95 22.6749 112.898 23.0953H103.847ZM106.425 17.2082C105.671 17.2082 105.066 17.4886 104.61 18.0493C104.154 18.5925 103.882 19.4334 103.794 20.5724H108.925C108.89 18.3297 108.057 17.2082 106.425 17.2082Z"
            fill={isDark ? 'var(--darkestGray)' : 'var(--white)'}
          />
        </>
      )}
      <path
        d="M18.5067 36.9718C28.7276 36.9718 37.0134 28.6954 37.0134 18.4859C37.0134 8.27643 28.7276 0 18.5067 0C8.28572 0 0 8.27643 0 18.4859C0 28.6954 8.28572 36.9718 18.5067 36.9718Z"
        fill={isWhite ? 'none' : 'var(--white)'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6084 36.9718C28.8855 36.9718 37.2167 28.6954 37.2167 18.4859C37.2167 8.27643 28.8855 0 18.6084 0C8.33125 0 0 8.27643 0 18.4859C0 28.6954 8.33125 36.9718 18.6084 36.9718ZM19.4543 11.8838C9.93037 15.7002 9.81156 21.1692 19.3948 24.8479C1.80455 22.4814 1.67936 13.5571 19.4543 11.8838ZM16.9341 29.2894C27.5598 23.0013 27.5204 13.7747 16.7959 7.68246C35.8309 13.3241 35.7372 21.7462 16.9341 29.2894Z"
        fill={isWhite ? 'var(--white)' : 'var(--brandColor)'}
      />
    </svg>
  )
}
