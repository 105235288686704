import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

export function Nav() {
  return (
    <>
      <NavWrapper className=" header__nav flex">
        <div className="flex">
          <Link className="nav-link" to="/services" aria-label="Services page">
            Services
          </Link>
          <div className="dropdown">
            <Link className="nav-link" to="/menus" aria-haspopup aria-label="Products">
              Products
            </Link>
            <div className="dropdown-menu" aria-label="dropdown-menu">
              <div className="triangle"></div>
              <ul>
                <li>
                  <Link className="nav-link" to="/menus" aria-label="Digital Menu page">
                    Digital Menus
                  </Link>
                </li>
                <li>
                  <Link className="nav-link" to="/sites" aria-label="Website Templates page">
                    Website Templates
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <Link className="nav-link" to="/about" aria-label="About page">
            About
          </Link>
        </div>
        <div>
          {/* <button onClick={() => {}} className="cta cta--white-ghost cta--small">
          Sign In
        </button> */}
          <Link className="cta cta--white cta--small" to="/contact">
            Contact Us
          </Link>
        </div>
      </NavWrapper>
    </>
  )
}

const NavWrapper = styled.nav`
  .user-nav {
    > * + * {
      margin-left: var(--space);
    }
    .dashboard-nav-link {
      color: var(--textColor);
      display: block;
    }
  }
`
