import * as React from 'react'

import { Header } from '../components/Header'
import { Footer } from '../components/Footer'

import '../styles/index.scss'

const Layout = ({ children }) => {
  return (
    <>
      <a className="skip-link" href="#main">
        Skip to content
      </a>
      <div id="layout-wrapper">
        <Header />
        <main id="main">{children}</main>
        <Footer />
      </div>
    </>
  )
}

export default Layout
